import type { AxiosRequestConfig } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr'
import { apiCaller } from '@wordup/lib-portal/features'

const useNsSWR = <T>({
  apiConfig,
  swrConfig,
  includeHeaders = false,
  shouldCamelcaseData = true,
}: {
  apiConfig: Parameters<typeof apiCaller<T>>[0] | null
  swrConfig?: SWRConfiguration<T>
  includeHeaders?: boolean
  shouldCamelcaseData?: boolean
}): SWRResponse<T> => {
  const fetcher = async (apiConfig: AxiosRequestConfig) => {
    const { data, headers } = await apiCaller<T>(apiConfig)

    const finalData = shouldCamelcaseData ? (data as any) : data

    return includeHeaders ? { data: finalData, headers } : finalData
  }

  return useSWR(apiConfig, fetcher, swrConfig)
}

export default useNsSWR

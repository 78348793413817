import { useEffect } from 'react'

/**
 *
 * @param {string} param.id - The id of the element to scroll to.
 * @param {boolean} param.shouldScroll - If true, scroll to the element.
 * @param {number} param.offset - The amount of time to wait before scrolling to the element. Defaults to 500ms.
 *
 */

export function useScrollTo(param: {
  id?: string
  shouldScroll: boolean
  offset: number
  onSuccess?: () => void
  scrollElementProps?: ScrollIntoViewOptions
}) {
  const { id, shouldScroll, offset = 500, onSuccess, scrollElementProps } = param

  useEffect(() => {
    if (!shouldScroll || !id) {
      return
    }

    const scrollTimer = setTimeout(() => {
      const targetElement = document.getElementById(id)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', ...scrollElementProps })
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      }
    }, offset)

    return () => {
      clearTimeout(scrollTimer)
    }
  }, [shouldScroll])
}

import { useEffect } from 'react'

// Disable swipe on page to prevent navigate swipe back
export const useDisableSwipe = () => {
  useEffect(() => {
    document.body.style.touchAction = 'none'
    document.body.style.overscrollBehavior = 'none'

    return () => {
      document.body.style.touchAction = 'auto'
      document.body.style.overscrollBehavior = 'auto'
    }
  }, [])
}

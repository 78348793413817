import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

interface UsePromptProps {
  onBlock?: ({ unblock, nextRoute }: { unblock: () => void; nextRoute: string }) => void
  enableBlockRouteChange?: boolean
}

export const usePrompt = ({ onBlock, enableBlockRouteChange = false }: UsePromptProps) => {
  const router = useRouter()
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [routeLoading, setRouteLoading] = useState(false)
  const [navigationConfig, setNavigationConfig] = useState<{
    nextRoute: string | null
  }>({
    nextRoute: null,
  })

  useEffect(() => {
    const handleRouteChangeStart = (route: string) => {
      if (!enableBlockRouteChange || hasConfirmed) {
        setRouteLoading(true)
        return
      }

      setNavigationConfig({ nextRoute: route })
      if (onBlock) {
        onBlock({ unblock: stopBlocking, nextRoute: route })
      }
      router.events.emit('routeChangeError')
      throw 'navigation aborted'
    }

    const handleRouteChangeComplete = () => {
      setRouteLoading(false)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeComplete)
    }
  }, [hasConfirmed, enableBlockRouteChange, onBlock, router])

  useEffect(() => {
    if (hasConfirmed && navigationConfig.nextRoute) {
      router.push(navigationConfig.nextRoute)
    }
  }, [hasConfirmed, navigationConfig, router])

  const startBlocking = () => {
    setHasConfirmed(false)
  }

  const stopBlocking = () => {
    setHasConfirmed(true)
  }

  return { startBlocking, stopBlocking, routeLoading }
}

import { AxiosResponse } from 'axios'

import {
  CreateCashbackRequestResponse,
  GetCashbackRequestInfoResponse,
  GetDefaultCreditCardResponse,
  GetJoinedCashbackMissionsResponse,
  GetMeResponse,
  PatchMePayload,
  PatchPasswordPayload,
} from '@/typings/api'

import fire from '@/apis/fire'

const userService = {
  get: () =>
    fire({ method: 'get', url: '/me', params: { expose_address: true } }) as Promise<
      AxiosResponse<GetMeResponse>
    >,

  update: (payload: Partial<PatchMePayload>) =>
    fire({ method: 'patch', url: '/me', data: { ...payload } }) as Promise<AxiosResponse<any>>,

  updatePassword: (payload: Required<PatchPasswordPayload>) =>
    fire({ method: 'patch', url: '/auth/password', data: { ...payload } }) as Promise<
      AxiosResponse<any>
    >,

  redeem: (code: string) =>
    fire({ method: 'post', url: `/redeem_codes/${code}/redemption` }) as Promise<
      AxiosResponse<any>
    >,

  getDefaultCreditCard: () =>
    fire({ method: 'get', url: '/payment_methods/default' }) as Promise<
      AxiosResponse<GetDefaultCreditCardResponse>
    >,

  deletePaymentMethod: ({ id, type }: { id: string; type: 'credit_card' }) =>
    fire({
      method: 'delete',
      url: `/payment_methods/${id}`,
      params: {
        type,
      },
    }) as Promise<AxiosResponse<{ success: boolean }>>,

  getJoinedCashbackMissions: () =>
    fire({ method: 'get', url: '/moneyback_requests' }) as Promise<
      AxiosResponse<GetJoinedCashbackMissionsResponse>
    >,

  getCashbackRequestInfo: ({ missionId }: { missionId: string }) =>
    fire({ method: 'get', url: `/moneyback_requests/${missionId}` }) as Promise<
      AxiosResponse<GetCashbackRequestInfoResponse>
    >,

  createCashbackForm: (payload: { [key: string]: any }) =>
    fire({
      method: 'post',
      url: '/moneyback_requests',
      data: { ...payload },
    }) as Promise<AxiosResponse<CreateCashbackRequestResponse>>,

  updateCashbackForm: ({ mrId, payload }: { mrId: string; payload: { [key: string]: any } }) =>
    fire({
      method: 'patch',
      url: `/moneyback_requests/${mrId}`,
      data: { ...payload },
    }) as Promise<AxiosResponse<any>>,
  addNewCreditCard: ({ payload }: { payload: { [key: string]: any } }) =>
    fire({ method: 'post', url: `/credit_cards`, data: { ...payload } }) as Promise<
      AxiosResponse<{ success: boolean }>
    >,
}

export default userService
